import {WheelOfFortuneData} from './WheelOfFortuneWrapper';
import Icon_Cards from '../../../assets/wheelOfFortune/Icon_Cards.png';
import Icon_Crown from '../../../assets/wheelOfFortune/Icon_Crown.png';
import Icon_Dice from '../../../assets/wheelOfFortune/Icon_Dice.png';
import Icon_Egg from '../../../assets/wheelOfFortune/Icon_Egg.png';
import Icon_Food_Can from '../../../assets/wheelOfFortune/Icon_Food_Can.png';
import Icon_Gem_Pearl from '../../../assets/wheelOfFortune/Icon_Gem_Pearl.png';
import Icon_Gem01_Green from '../../../assets/wheelOfFortune/Icon_Gem01_Green.png';
import Icon_Golds from '../../../assets/wheelOfFortune/Icon_Golds.png';

export const wheelOfFortuneTestData: WheelOfFortuneData = {
  durationInSeconds: 11,
  isSlowSpeedReduction: true,
  options: [
    {
      img: Icon_Cards,
      title: '25%',
      subTitle: 'subtitle',
      color: '',
      dropChance: '25',
      id: 'Icon_Cards',
      prize: {
        img: Icon_Cards,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: false,
    },
    {
      img: Icon_Crown,
      title: 'base',
      color: '',
      dropChance: 'base',
      id: 'Icon_Crown',
      prize: {
        img: Icon_Crown,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: true,
    },
    {
      img: Icon_Dice,
      title: 'base',
      color: '',
      dropChance: 'base',
      id: 'Icon_Dice',
      prize: {
        img: Icon_Dice,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: false,
    },
    {
      img: Icon_Egg,
      title: '30%',
      subTitle: 'subtitle',
      color: '',
      dropChance: '30',
      id: 'Icon_Egg',
      prize: {
        img: Icon_Egg,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: true,
    },
    {
      img: Icon_Food_Can,
      title: 'base',
      subTitle: 'subtitle',
      color: '',
      dropChance: 'base',
      id: 'Icon_Food_Can',
      prize: {
        img: Icon_Food_Can,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: false,
    },
    {
      img: Icon_Gem_Pearl,
      title: 'base',
      color: '',
      dropChance: 'base',
      id: 'Icon_Gem_Pearl',
      prize: {
        img: Icon_Gem_Pearl,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: false,
    },
    {
      img: Icon_Gem01_Green,
      title: 'base',
      subTitle: 'subtitle',
      color: '',
      dropChance: 'base',
      id: 'Icon_Gem01_Green',
      prize: {
        img: Icon_Gem01_Green,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: false,
    },
    {
      img: Icon_Golds,
      title: '10%',
      color: '',
      dropChance: '10',
      id: 'Icon_Golds',
      prize: {
        img: Icon_Golds,
        title: 'Prize title',
        description: 'Prize description',
      },
      isParticle: true,
    },
  ],
};
