import {WheelOfFortuneData} from '../../../pages/scriptIntegrations/wheelOfFortune/WheelOfFortuneWrapper';
import WheelOfFortuneFactory, {
  WheelOfFortuneColorTypes,
  WheelOfFortuneSectionsTypes,
} from '../../../pages/scriptIntegrations/wheelOfFortune/WheelOfFortuneFactory';

export interface WheelOfFortuneAnimationsProps {
  wheelOfFortuneData: WheelOfFortuneData;
  optionWinnedWithId: (id: string) => void;
  type: WheelOfFortuneColorTypes;
  sections: WheelOfFortuneSectionsTypes;
  isStartSpin: boolean;
  startSpin: () => void;
  stopSpin: () => void;
}

const WheelOfFortuneAnimations: React.FC<WheelOfFortuneAnimationsProps> = ({
  wheelOfFortuneData,
  optionWinnedWithId,
  type,
  sections,
  isStartSpin,
  stopSpin,
  startSpin,
}) => {
  return (
    <div className="absolute w-full h-full flex justify-center items-center top-0 left-0 z-50 overflow-hidden select-none">
      <div className="absolute w-full h-full top-0 left-0 opacity-90 z-10 select-none" />
      <WheelOfFortuneFactory
        type={type}
        sections={sections}
        wheelOfFortuneProps={wheelOfFortuneData}
        optionWinnedWithId={optionWinnedWithId}
        isStartSpin={isStartSpin}
        startSpin={startSpin}
        stopSpin={stopSpin}
      />
    </div>
  );
};

export default WheelOfFortuneAnimations;
