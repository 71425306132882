import {forwardRef, useCallback, useRef, useImperativeHandle} from 'react';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';
import {WheelOfFortuneData} from '../../../pages/scriptIntegrations/wheelOfFortune/WheelOfFortuneWrapper';

export interface WheelOfFortuneCommunicatorSetParamsGettingEvent {
  event: 'setParams';
  wheelOfFortuneData: WheelOfFortuneData;
}

export interface WheelOfFortuneCommunicatorSetStartSpinGettingEvent {
  event: 'setStartSpin';
}

export interface WheelOfFortuneCommunicatorOnLoadedSendingEvent {
  event: 'onLoaded';
}

export interface WheelOfFortuneCommunicatorOptionWinnedWithIdSendingEvent {
  event: 'optionWinnedWithId';
  id: string;
}

interface WheelOfFortuneIntegrationProps {
  setParams: (wheelOfFortuneData: WheelOfFortuneData) => void;
  setStartSpin: () => void;
  componentId: string;
}

export interface WheelOfFortuneIntegrationRef {
  onLoaded: () => void;
  optionWinnedWithId: (id: string) => void;
}

const WheelOfFortuneEventHandler = forwardRef<
  WheelOfFortuneIntegrationRef,
  WheelOfFortuneIntegrationProps
>(({setParams, setStartSpin, componentId}, ref) => {
  const eventMessangerRef =
    useRef<
      EventSystemRefProps<
        | WheelOfFortuneCommunicatorOnLoadedSendingEvent
        | WheelOfFortuneCommunicatorOptionWinnedWithIdSendingEvent
      >
    >(null);

  const incomingEventsHandler = useCallback(
    (
      message:
        | WheelOfFortuneCommunicatorSetParamsGettingEvent
        | WheelOfFortuneCommunicatorSetStartSpinGettingEvent,
    ) => {
      switch (message.event) {
        case 'setParams':
          setParams(message.wheelOfFortuneData);
          return;
        case 'setStartSpin':
          setStartSpin();
          return;
      }
    },
    [setParams, setStartSpin],
  );

  useImperativeHandle(ref, () => ({
    onLoaded() {
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    },
    optionWinnedWithId(id) {
      eventMessangerRef.current?.sendMessage({
        event: 'optionWinnedWithId',
        id,
      });
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  return (
    <EventsMessanger<
      | WheelOfFortuneCommunicatorSetParamsGettingEvent
      | WheelOfFortuneCommunicatorSetStartSpinGettingEvent,
      | WheelOfFortuneCommunicatorOnLoadedSendingEvent
      | WheelOfFortuneCommunicatorOptionWinnedWithIdSendingEvent
    >
      componentName={componentId}
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
});

export default WheelOfFortuneEventHandler;
