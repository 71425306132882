import classNames from 'classnames';
import {CSSProperties, useCallback} from 'react';
import {DialogButton, DialogStyle} from '../Contract';

type SimpleDialogButtonProps = {
  onClick: () => void;
} & Omit<DialogStyle, 'backgroundColor'> &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style'> &
  Omit<DialogButton, 'id'>;

const SimpleDialogButton: React.FC<SimpleDialogButtonProps> = ({
  className,
  title,
  style = 'normal',

  mainColor,
  oppositeColor,
  mainTextColor,
  disabled,
  onClick,
}) => {
  const getStyle = useCallback((): CSSProperties => {
    switch (style) {
      case 'blank':
        return {
          color: mainTextColor,
          border: `1px solid ${mainColor}`,
        };

      case 'dashed':
        return {
          color: mainTextColor,
          border: `2px dashed ${mainColor}`,
        };
        break;

      default:
      case 'normal':
        return {
          color: oppositeColor,
          background: mainColor,
        };
    }
  }, [mainColor, mainTextColor, oppositeColor, style]);

  return (
    <button
      className={classNames(
        'text-center cursor-pointer px-3 py-2 rounded-lg hover:scale-105 transition ease-in-out',
        disabled && 'opacity-75',
        className,
      )}
      style={getStyle()}
      onClick={onClick}
      disabled={disabled}
      // {...rest}
    >
      <span className="z-30 pointer-events-none">{title}</span>
    </button>
  );
};

export default SimpleDialogButton;
