import {useRef} from 'react';
import {useParams} from 'react-router-dom';
import {
  ActiveTypeBox,
  OnboardingChestRef,
} from '../../../components/animatedCharacters/onboardingChest/Contract';
import ChestScriptIntegration, {
  ChestScriptIntegrationRef,
} from '../../../components/scriptIntegrations/ChestIntegration';
import ChestFactory, {ChestTypes} from './ChestFactory';

const ChestWrapper: React.FC = () => {
  const {type, activeType, id} = useParams<{
    type: ChestTypes;
    activeType: ActiveTypeBox;
    id: string;
  }>();

  const chestRef = useRef<OnboardingChestRef>(null);
  const chestScriptIntegrationRef = useRef<ChestScriptIntegrationRef>(null);

  return (
    <ChestScriptIntegration
      componentId={id || 'chest'}
      open={(playOnce: boolean) => {
        console.log('Getting event: Open');
        chestRef.current?.open(playOnce);
      }}
      setUp={(object) => {
        console.log('Getting event: SetUp');
        console.log('Object name:', object.text);
      }}
      ref={chestScriptIntegrationRef}
    >
      <ChestFactory
        type={type || 'bowBox'}
        properties={{
          onLoaded: () => {
            console.log('Sending event: onLoaded');
            chestScriptIntegrationRef.current?.onLoaded();
          },
          onInnerContentClick: () => {
            console.log('Sending event: onInnerContentClick');
            chestScriptIntegrationRef.current?.onInnerContentClick();
          },
          onChestOpened: () => {
            chestScriptIntegrationRef.current?.onChestOpened();
          },
          activeTypeBox: activeType || 'click',
        }}
        ref={chestRef}
      />
    </ChestScriptIntegration>
  );
};

export default ChestWrapper;
