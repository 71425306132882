import {useEffect, useState, useCallback} from 'react';
import {motion} from 'framer-motion';
import useSound from 'use-sound';
import classNames from 'classnames';
import clickOnWheel from '../../../assets/wheelOfFortune/sounds/startWheel_cut.mp3';
import clickWheel from '../../../assets/wheelOfFortune/sounds/click_wheel.mp3';
import winSound from '../../../assets/wheelOfFortune/sounds/win.mp3';
import {
  WheelOfFortuneData,
  WheelOfFortuneOptionsPrizeData,
} from './WheelOfFortuneWrapper';
import play_Roulette_Bg from '../../../assets/wheelOfFortune/Play_Roulette_Bg.png';
import play_Roulette_Bg_beige from '../../../assets/wheelOfFortune/Play_Roulette_Bg_beige.png';
import play_Roulette_Btn_spin from '../../../assets/wheelOfFortune/Play_Roulette_Btn_spin.png';
import play_Roulette_Btn_spin_beige from '../../../assets/wheelOfFortune/Play_Roulette_Btn_spin_beige.png';
import Play_Roulette_Arrow from '../../../assets/wheelOfFortune/Play_Roulette_Arrow.png';
import {randomInteger} from '../../../utils/random';
import './WheelOfFortuneFactory.css';

export type WheelOfFortuneColorTypes = 'blue' | 'beige';
export type WheelOfFortuneSectionsTypes = 'adaptive' | 'equal';

interface WheelOfFortuneFactoryProps {
  type: WheelOfFortuneColorTypes;
  sections: WheelOfFortuneSectionsTypes;
  wheelOfFortuneProps: WheelOfFortuneData;
  optionWinnedWithId: (id: string) => void;
  isStartSpin: boolean;
  stopSpin: () => void;
  startSpin: () => void;
}

const WheelOfFortuneFactory: React.FC<WheelOfFortuneFactoryProps> = ({
  type,
  sections,
  wheelOfFortuneProps,
  optionWinnedWithId,
  isStartSpin,
  stopSpin,
  startSpin,
}) => {
  const [bg, setBg] = useState(''); // тут формируется свойство background со свойством conic-gradient, чтобы делать в виде круговой диаграммы колесо
  const [mapPercentFilling, setMapPercentFilling] = useState<number[]>([]); // тут массив из процентов заполнения колеса (это же и шанс выпадения, тут прямая взаимосвязь - чем больше шанс выпасть, тем больше заполнение колеса). Пример, по порядку [5, 20, 75] - это значит первый блок будет 5%, второй 20% и третий огромный заполнит 75% пространства колеса
  const [minSpin, setMinSpin] = useState(720 * 5); // минимальное количество оборотов для колеса. 360 это один полный оборот. Т.е. мы принимаем параметр времени кручения, а скорость будет зависить от этого параметра. За каждую секунду времени кручения делать по 2 оборота (720 градусов). По умолчанию 5 секунд время кручения. Если будет 2 секунды, то минимальный оборот будет 720 * 2, чтобы сохранять одну скорость.
  const [additionalTurn, setAdditionalTurn] = useState(0); // тут считаются дополнительные градусы поворота, от 0 до 360. Зависит от выпавшего кубика от 1 до 99
  const [oneStepEqualMode, setOneStepEqualMode] = useState(
    +(100 / wheelOfFortuneProps.options.length).toFixed(2),
  ); // для режима equal, когда все ячейки одинакового размера. Тут в процентах указано сколько каждая занимает
  const [isStartRouletteArrow, setIsStartRouletteArrow] = useState(false); // "дёргануть" стрелку один раз
  const [isShowPrizeMessage, setIsShowPrizeMessage] = useState(false); // показывать ли сообщение о призе
  const [prizeMessage, setPrizeMessage] =
    useState<WheelOfFortuneOptionsPrizeData | null>(null); // содержимое сообщения о призе
  const [centerTarget, setCenterTarget] = useState(0); // тут хранится на какой угол нужно подвинуть колесо, чтобы указать конкретно на центр выпавшей ячейки. Используется в режиме adaptive, когда мы попадаем на нужную область, потом следует "доводка" до центра этой области
  const [playSoundClickOnWheel] = useSound(clickOnWheel, {volume: 0.2});
  const [playSoundClickWheel] = useSound(clickWheel, {
    volume: 0.2,
  });
  const [playSoundWin] = useSound(winSound, {
    volume: 0.4,
  });
  const [isSoundSpinningWheelEnable, setIsSoundSpinningWheelEnable] =
    useState(false); // звук во время кручения колеса
  const [randomAdditionalTurn, setRandomAdditionalTurn] = useState(0); // это рандомный угол поворота в режиме equal, без него стрелка всегда показывает на центр ячейки, а нам нужно, чтобы она рандомно куда-то в ячейке показала и потом докрутилась до цента.

  // ф-ия выполняется когда isStartSpin = true
  const onWheelSpinnig = useCallback(() => {
    if (wheelOfFortuneProps.options.length === 0) {
      stopSpin();
      return;
    }
    const rand = randomInteger(1, 99);
    // console.log(rand);
    let addSpin = 0; // на какой угол повернуть колесо, зависит от rand. rand это случайное число от 1 до 99, оно соответствует повороту колеса от 1 до 99 процентов. В addSpin будет записан угол от 0 до 360 в зависимости от rand

    const filter = mapPercentFilling.filter((perc) => perc <= rand); // в зависимости от rand, сюда запишем отфильтрованный массив всех процентов заполнения колеса, но не больше выпавшего значения rand. Последнее значение и будет нам нужно. На него значит и показывает стрелочка

    if (sections === 'adaptive') {
      addSpin = 360 - (rand * 360) / 100;
    }
    if (sections === 'equal') {
      addSpin =
        360 -
        ((filter.length * oneStepEqualMode + oneStepEqualMode / 2) * 360) / 100; // в режиме equal угол рассчитывается иначе, т.к. тут все ячейки одного размера
    }
    addSpin = +addSpin.toFixed(2);
    setAdditionalTurn(addSpin);
    setRandomAdditionalTurn(
      +(
        addSpin +
        ((oneStepEqualMode / randomInteger(2, 10)) * 360) / 100 -
        ((oneStepEqualMode / randomInteger(2, 10)) * 360) / 100
      ).toFixed(2),
    );

    const id = wheelOfFortuneProps.options[filter.length].id;
    // console.log('message with id:', id);
    optionWinnedWithId(id);

    let halfTarget = 0; // проценты выпавшей ячейки, деленые пополам, чтобы точно середину получить. Нужно нам для "доводчика" в режиме adaptive
    if (rand < +mapPercentFilling[0]) {
      halfTarget = +mapPercentFilling[filter.length] / 2;
      setCenterTarget(360 - (halfTarget * 360) / 100);
    } else {
      halfTarget =
        (+mapPercentFilling[filter.length] -
          +mapPercentFilling[filter.length - 1]) /
        2;
      setCenterTarget(
        360 - ((mapPercentFilling[filter.length - 1] + halfTarget) * 360) / 100,
      );
    }

    setPrizeMessage(wheelOfFortuneProps.options[filter.length].prize);
  }, [
    mapPercentFilling,
    wheelOfFortuneProps,
    optionWinnedWithId,
    sections,
    oneStepEqualMode,
    stopSpin,
  ]);

  // функция вызывает щелчок и поворот стрелки (в синем дизайне, в верхней части колеса)
  const onStartAnimationArrow = useCallback(() => {
    playSoundClickWheel();
    setIsStartRouletteArrow(true);
  }, [playSoundClickWheel]);

  useEffect(() => {
    setMinSpin(720 * wheelOfFortuneProps.durationInSeconds);
    setAdditionalTurn(0);

    let bgPercents: string[] = [];

    const numberBase = wheelOfFortuneProps.options.filter(
      (option) => option.dropChance === 'base',
    ).length;
    let amountNotBasePercent = 0;
    wheelOfFortuneProps.options.forEach((option) => {
      if (option.dropChance !== 'base') {
        amountNotBasePercent += +option.dropChance;
      }
    });

    wheelOfFortuneProps.options.forEach((option) => {
      if (option.dropChance === 'base') {
        bgPercents.push(((100 - amountNotBasePercent) / numberBase).toFixed(2));
      } else {
        bgPercents.push(option.dropChance);
      }
    });

    let currentFilling = 0;

    const currentFills: number[] = [];

    if (sections === 'adaptive') {
      bgPercents = bgPercents.map((option, i) => {
        currentFilling += +option;
        currentFills.push(+currentFilling.toFixed(2));
        return i === 0
          ? `0% ${option}%`
          : `${currentFilling - +option}% ${currentFilling}%`;
      });
    }

    if (sections === 'equal') {
      bgPercents = bgPercents.map((option, i) => {
        currentFilling += +option;
        currentFills.push(+currentFilling.toFixed(2));
        return i === 0
          ? `0% ${oneStepEqualMode}%`
          : `${oneStepEqualMode * i}% ${oneStepEqualMode * (i + 1)}%`;
      });
    }

    setMapPercentFilling(currentFills);

    let newBg = 'conic-gradient(';
    bgPercents.forEach((percent, index) => {
      if (wheelOfFortuneProps.options[index].color) {
        newBg += wheelOfFortuneProps.options[index].color + ' ';
      } else {
        if (index % 2 === 0) {
          newBg +=
            wheelOfFortuneProps.options[index].isParticle && type === 'beige'
              ? '#EB5124 '
              : type === 'blue'
              ? '#005AEF '
              : '#FEF9EC ';
        } else {
          newBg +=
            wheelOfFortuneProps.options[index].isParticle && type === 'beige'
              ? '#EB5124 '
              : type === 'blue'
              ? '#008DEF '
              : '#FFECCA ';
        }
      }

      if (index === bgPercents.length - 1) {
        newBg += bgPercents[index];
      } else {
        newBg += bgPercents[index] + ', ';
      }
    });
    newBg += ')';
    setBg(newBg);
  }, [wheelOfFortuneProps, sections, oneStepEqualMode, type]);

  useEffect(() => {
    if (isStartSpin) onWheelSpinnig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartSpin]);

  useEffect(() => {
    setOneStepEqualMode(+(100 / wheelOfFortuneProps.options.length).toFixed(2));
  }, [wheelOfFortuneProps.options.length]);

  useEffect(() => {
    if (!isSoundSpinningWheelEnable) return;
    let interval: NodeJS.Timer;

    if (wheelOfFortuneProps.isSlowSpeedReduction) {
      interval = setInterval(() => {
        onStartAnimationArrow();
      }, 100);

      setTimeout(() => {
        clearTimeout(interval);
        interval = setInterval(() => {
          onStartAnimationArrow();
        }, 200);
      }, wheelOfFortuneProps.durationInSeconds * 150);

      setTimeout(() => {
        clearTimeout(interval);
        interval = setInterval(() => {
          onStartAnimationArrow();
        }, 350);
      }, wheelOfFortuneProps.durationInSeconds * 250);

      setTimeout(() => {
        clearTimeout(interval);
        interval = setInterval(() => {
          onStartAnimationArrow();
        }, 500);
      }, wheelOfFortuneProps.durationInSeconds * 350);

      setTimeout(() => {
        clearTimeout(interval);
        interval = setInterval(() => {
          onStartAnimationArrow();
        }, 700);
      }, wheelOfFortuneProps.durationInSeconds * 450);

      setTimeout(() => {
        clearTimeout(interval);
        interval = setInterval(() => {
          onStartAnimationArrow();
        }, 900);
      }, wheelOfFortuneProps.durationInSeconds * 550);

      return () => {
        clearTimeout(interval);
      };
    } else {
      interval = setInterval(() => {
        onStartAnimationArrow();
      }, 200);

      setTimeout(() => {
        clearTimeout(interval);
        interval = setInterval(() => {
          onStartAnimationArrow();
        }, 100);
      }, wheelOfFortuneProps.durationInSeconds * 100);

      setTimeout(() => {
        clearTimeout(interval);
        interval = setInterval(() => {
          onStartAnimationArrow();
        }, 200);
      }, wheelOfFortuneProps.durationInSeconds * 650);

      return () => {
        clearTimeout(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoundSpinningWheelEnable, playSoundClickWheel]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     startSpin();
  //   }, 0);
  // }, []);

  switch (type) {
    case 'blue':
      return (
        <div
          className="aspect-square w-full max-w-2xl flex justify-center items-center relative z-10 select-none cursor-pointer"
          id="wheelOfFortuneContainer"
          onClick={() => {
            if (!isStartSpin && wheelOfFortuneProps.options.length !== 0) {
              playSoundClickOnWheel();
              setIsSoundSpinningWheelEnable(true);
              setTimeout(() => {
                setIsSoundSpinningWheelEnable(false);
              }, wheelOfFortuneProps.durationInSeconds * (wheelOfFortuneProps.isSlowSpeedReduction ? 0.7 : 0.75) * 1000);
              startSpin();
            }
          }}
        >
          <motion.div
            animate={{
              opacity: !isShowPrizeMessage ? 0 : [0.5, 1],
              height: !isShowPrizeMessage ? '0px' : '80%',
              transition: {
                ease: 'backInOut',
                duration: 0.5,
              },
            }}
            initial={{opacity: 0, height: 0}}
            className="absolute bg-white z-20 w-4/5 h-4/5 flex justify-center items-center rounded-[32px] font-sigmarOne overflow-hidden wheel-of-fortune-box-shadow wheel-of-fortune-text-shadow text-white select-none"
          >
            <div className="flex flex-col items-center gap-[10px] w-[85%]">
              <img src={prizeMessage?.img} alt="Prize" />
              <hr className="w-full" />
              <div className="text-2xl sm:text-5xl">{prizeMessage?.title}</div>
              <div className="text-lg sm:text-2xl">
                {prizeMessage?.description}
              </div>
            </div>
          </motion.div>

          <img
            src={play_Roulette_Bg}
            alt="play_Roulette_Bg"
            className="absolute top-0 left-0 w-full select-none"
          />

          <motion.div
            animate={{
              rotate: !isStartSpin
                ? [null, 0]
                : wheelOfFortuneProps.isSlowSpeedReduction
                ? [null, minSpin + additionalTurn]
                : sections === 'adaptive'
                ? [null, minSpin + additionalTurn, minSpin + centerTarget]
                : [
                    null,
                    minSpin + randomAdditionalTurn,
                    minSpin + additionalTurn,
                  ],
              transition: {
                duration: isStartSpin
                  ? wheelOfFortuneProps.durationInSeconds
                  : 0,
                ease: wheelOfFortuneProps.isSlowSpeedReduction
                  ? [0, 0.9, 0, 1]
                  : 'easeInOut',
                times: wheelOfFortuneProps.isSlowSpeedReduction
                  ? [0, 1]
                  : [0, 0.8, 1],
              },
            }}
            onAnimationComplete={() => {
              if (isStartSpin) {
                playSoundClickWheel();

                setTimeout(() => {
                  setIsShowPrizeMessage(true);
                  playSoundWin();
                }, 100);

                setTimeout(() => {
                  stopSpin();
                  setIsShowPrizeMessage(false);
                }, 3000);
              }
            }}
            className="w-[90%] aspect-square rounded-full absolute roulette-inner-shadow select-none overflow-hidden"
            style={{background: bg}}
          >
            {sections === 'adaptive'
              ? wheelOfFortuneProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        transform:
                          index === 0
                            ? `rotate(${
                                (360 * mapPercentFilling[index]) / 2 / 100 + 45
                              }deg)`
                            : `rotate(${
                                (360 *
                                  (mapPercentFilling[index - 1] +
                                    (mapPercentFilling[index] -
                                      mapPercentFilling[index - 1]) /
                                      2)) /
                                  100 +
                                45
                              }deg)`,
                      }}
                      className="wheel-number absolute aspect-square origin-bottom-right w-1/2 flex justify-center items-center z-10 overflow-hidden"
                    >
                      {option.isParticle && (
                        <>
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.5, 0.7, 0.5],
                              transition: {
                                duration: 1.5,
                                repeat: Infinity,
                                repeatDelay: 0.8,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[70%] left-[70%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.6, 0.9, 0.6],
                              transition: {
                                duration: 1.5,
                                repeatDelay: 0.3,
                                repeat: Infinity,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[30%] left-[30%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.4, 0.6, 0.4],
                              transition: {
                                duration: 2,
                                repeat: Infinity,
                                repeatDelay: 1,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[60%] left-[50%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.3, 0.8, 0.3],
                              transition: {
                                duration: 1,
                                repeat: Infinity,
                                repeatDelay: 0.5,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[40%] left-[50%] absolute"
                          />
                        </>
                      )}
                      <span className="font-sigmarOne text-white wheel-of-fortune-text-shadow select-none text-xs sm:text-xl -rotate-45 absolute right-[25%] bottom-[30%]">
                        {option.title}
                      </span>
                      {option.img && (
                        <img
                          src={option.img}
                          alt="prize"
                          className="select-none -rotate-45 absolute w-10 sm:w-20 top-[35%] left-[35%]"
                        />
                      )}
                      <motion.span className="select-none absolute bg-white top-[32%] left-[32%] w-3 aspect-square rounded-full" />
                    </div>
                  );
                })
              : wheelOfFortuneProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        transform:
                          index === 0
                            ? `rotate(${
                                (360 * oneStepEqualMode) / 2 / 100 + 45
                              }deg)`
                            : `rotate(${
                                (360 *
                                  (oneStepEqualMode * (index + 1) -
                                    oneStepEqualMode / 2)) /
                                  100 +
                                45
                              }deg)`,
                      }}
                      className="wheel-number absolute aspect-square origin-bottom-right w-1/2 flex justify-center items-center z-10"
                    >
                      {option.isParticle && (
                        <>
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.5, 0.7, 0.5],
                              transition: {
                                duration: 1.5,
                                repeat: Infinity,
                                repeatDelay: 0.8,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[70%] left-[70%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.6, 0.9, 0.6],
                              transition: {
                                duration: 1.5,
                                repeatDelay: 0.3,
                                repeat: Infinity,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[30%] left-[30%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.4, 0.6, 0.4],
                              transition: {
                                duration: 2,
                                repeat: Infinity,
                                repeatDelay: 1,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[60%] left-[50%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.3, 0.8, 0.3],
                              transition: {
                                duration: 1,
                                repeat: Infinity,
                                repeatDelay: 0.5,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[40%] left-[50%] absolute"
                          />
                        </>
                      )}
                      <span className="font-sigmarOne text-white wheel-of-fortune-text-shadow select-none text-xs sm:text-xl -rotate-45 absolute right-[25%] bottom-[30%]">
                        {option.title}
                      </span>
                      {option.img && (
                        <img
                          src={option.img}
                          alt="prize"
                          className="select-none -rotate-45 absolute w-10 sm:w-20 top-[35%] left-[35%]"
                        />
                      )}
                      <motion.span className="select-none absolute bg-white top-[32%] left-[32%] w-3 aspect-square rounded-full" />
                    </div>
                  );
                })}
          </motion.div>
          <img
            src={play_Roulette_Btn_spin}
            className="absolute w-1/5 z-10 select-none"
          />
          <div className="z-10 font-sigmarOne text-white wheel-of-fortune-text-shadow select-none text-base sm:text-2xl">
            SPIN!
          </div>
          <motion.img
            animate={{
              rotate: isStartRouletteArrow ? [0, -30] : 0,
            }}
            transition={{
              duration: 0.08,
            }}
            onAnimationComplete={() => setIsStartRouletteArrow(false)}
            src={Play_Roulette_Arrow}
            alt="Play_Roulette_Arrow"
            className="z-10 select-none absolute top-[-3.5%] w-10 sm:w-16"
          />
          {wheelOfFortuneProps.options.length === 0 && (
            <>
              <div className="absolute z-10 font-sigmarOne text-white wheel-of-fortune-text-shadow select-none text-2xl sm:text-4xl top-1/4">
                No options
              </div>
              <div className="absolute z-10 font-sigmarOne text-white wheel-of-fortune-text-shadow select-none text-2xl sm:text-4xl bottom-1/4">
                No options
              </div>
            </>
          )}
        </div>
      );
    case 'beige':
      return (
        <div
          className="aspect-square w-full max-w-2xl flex justify-center items-center relative z-10 select-none cursor-pointer"
          id="wheelOfFortuneContainer"
          onClick={() => {
            if (!isStartSpin && wheelOfFortuneProps.options.length !== 0) {
              playSoundClickOnWheel();
              setIsSoundSpinningWheelEnable(true);
              setTimeout(() => {
                setIsSoundSpinningWheelEnable(false);
              }, wheelOfFortuneProps.durationInSeconds * (wheelOfFortuneProps.isSlowSpeedReduction ? 0.7 : 0.75) * 1000);
              startSpin();
            }
          }}
        >
          <motion.div
            animate={{
              opacity: !isShowPrizeMessage ? 0 : [0.5, 1],
              height: !isShowPrizeMessage ? '0px' : '80%',
              transition: {
                ease: 'backInOut',
                duration: 0.5,
              },
            }}
            initial={{opacity: 0, height: 0}}
            className="absolute bg-white z-20 w-4/5 h-4/5 flex justify-center items-center rounded-[32px] font-sigmarOne overflow-hidden wheel-of-fortune-box-shadow wheel-of-fortune-text-shadow text-white select-none"
          >
            <div className="flex flex-col items-center gap-[10px] w-[85%]">
              <img src={prizeMessage?.img} alt="Prize" />
              <hr className="w-full" />
              <div className="text-2xl sm:text-5xl">{prizeMessage?.title}</div>
              <div className="text-lg sm:text-2xl">
                {prizeMessage?.description}
              </div>
            </div>
          </motion.div>

          <img
            src={play_Roulette_Bg_beige}
            alt="play_Roulette_Bg"
            className="absolute top-0 left-0 w-full select-none"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[4.6%] left-[30.4%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[1.1%] left-[48.8%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[4.4%] left-[66.5%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[15.2%] left-[82.5%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[31.2%] left-[92.9%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[48.8%] left-[96.5%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[66.8%] left-[92.9%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[82.5%] left-[82.5%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[92.8%] left-[66.5%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[96.4%] left-[48.8%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[92.8%] left-[30.4%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[82.6%] left-[14.9%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[67.2%] left-[4.7%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[48.8%] left-[1.0%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#FFFCF3', '#63310D', '#FFFCF3'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[31.2%] left-[4.7%] blur-[0.6px]"
          />
          <motion.div
            animate={{
              background: ['#63310D', '#FFFCF3', '#63310D'],
              transition: {
                duration: 1,
                repeatType: 'loop',
                repeat: Infinity,
              },
            }}
            className="absolute aspect-square rounded-full select-none w-[2.5%] top-[15.2%] left-[14.9%] blur-[0.6px]"
          />

          <motion.div
            animate={{
              rotate: !isStartSpin
                ? [null, 0]
                : wheelOfFortuneProps.isSlowSpeedReduction
                ? [null, minSpin + additionalTurn]
                : sections === 'adaptive'
                ? [null, minSpin + additionalTurn, minSpin + centerTarget]
                : [
                    null,
                    minSpin + randomAdditionalTurn,
                    minSpin + additionalTurn,
                  ],
              transition: {
                duration: isStartSpin
                  ? wheelOfFortuneProps.durationInSeconds
                  : 0,
                ease: wheelOfFortuneProps.isSlowSpeedReduction
                  ? [0, 0.9, 0, 1]
                  : 'easeInOut',
                times: wheelOfFortuneProps.isSlowSpeedReduction
                  ? [0, 1]
                  : [0, 0.8, 1],
              },
            }}
            onAnimationComplete={() => {
              if (isStartSpin) {
                playSoundClickWheel();

                setTimeout(() => {
                  setIsShowPrizeMessage(true);
                  playSoundWin();
                }, 100);

                setTimeout(() => {
                  stopSpin();
                  setIsShowPrizeMessage(false);
                }, 3000);
              }
            }}
            className="w-[90%] aspect-square rounded-full absolute select-none overflow-hidden"
            style={{background: bg}}
          >
            <div className="w-full aspect-square rounded-full absolute select-none overflow-hidden border-[24px] border-solid border-black blur-[7px] opacity-50" />
            {sections === 'adaptive'
              ? wheelOfFortuneProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        transform:
                          index === 0
                            ? `rotate(${
                                (360 * mapPercentFilling[index]) / 2 / 100 + 45
                              }deg)`
                            : `rotate(${
                                (360 *
                                  (mapPercentFilling[index - 1] +
                                    (mapPercentFilling[index] -
                                      mapPercentFilling[index - 1]) /
                                      2)) /
                                  100 +
                                45
                              }deg)`,
                      }}
                      className="wheel-number absolute aspect-square origin-bottom-right w-1/2 flex justify-center items-center z-10 overflow-hidden"
                    >
                      {option.isParticle && (
                        <>
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.5, 0.7, 0.5],
                              transition: {
                                duration: 1.5,
                                repeat: Infinity,
                                repeatDelay: 0.8,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[70%] left-[70%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.6, 0.9, 0.6],
                              transition: {
                                duration: 1.5,
                                repeatDelay: 0.3,
                                repeat: Infinity,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[30%] left-[30%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.4, 0.6, 0.4],
                              transition: {
                                duration: 2,
                                repeat: Infinity,
                                repeatDelay: 1,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[60%] left-[50%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.3, 0.8, 0.3],
                              transition: {
                                duration: 1,
                                repeat: Infinity,
                                repeatDelay: 0.5,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[40%] left-[50%] absolute"
                          />
                        </>
                      )}
                      <span
                        className={classNames(
                          'font-jost font-bold select-none text-lg sm:text-3xl -rotate-45 absolute left-[35%] top-[40%]',
                          option.isParticle ? 'text-[#FFF9F5]' : 'text-black',
                        )}
                      >
                        {option.title}
                      </span>
                      <span
                        className={classNames(
                          'font-jost select-none text-xs sm:text-xl -rotate-45 absolute right-[30%] bottom-[35%]',
                          option.isParticle ? 'text-[#FFF9F5]' : 'text-black',
                        )}
                      >
                        {option.subTitle}
                      </span>
                    </div>
                  );
                })
              : wheelOfFortuneProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        transform:
                          index === 0
                            ? `rotate(${
                                (360 * oneStepEqualMode) / 2 / 100 + 45
                              }deg)`
                            : `rotate(${
                                (360 *
                                  (oneStepEqualMode * (index + 1) -
                                    oneStepEqualMode / 2)) /
                                  100 +
                                45
                              }deg)`,
                      }}
                      className="wheel-number absolute aspect-square origin-bottom-right w-1/2 flex justify-center items-center z-10"
                    >
                      {option.isParticle && (
                        <>
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.5, 0.7, 0.5],
                              transition: {
                                duration: 1.5,
                                repeat: Infinity,
                                repeatDelay: 0.8,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[70%] left-[70%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.6, 0.9, 0.6],
                              transition: {
                                duration: 1.5,
                                repeatDelay: 0.3,
                                repeat: Infinity,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[30%] left-[30%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.4, 0.6, 0.4],
                              transition: {
                                duration: 2,
                                repeat: Infinity,
                                repeatDelay: 1,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[60%] left-[50%] absolute"
                          />
                          <motion.div
                            animate={{
                              opacity: [0, 1, 0],
                              scale: [0.3, 0.8, 0.3],
                              transition: {
                                duration: 1,
                                repeat: Infinity,
                                repeatDelay: 0.5,
                              },
                            }}
                            className="wheel-of-fortune-four-pointed-star z-20 top-[40%] left-[50%] absolute"
                          />
                        </>
                      )}
                      <span
                        className={classNames(
                          'font-jost font-bold select-none text-lg sm:text-3xl -rotate-45 absolute left-[35%] top-[40%]',
                          option.isParticle ? 'text-[#FFF9F5]' : 'text-black',
                        )}
                      >
                        {option.title}
                      </span>
                      <span
                        className={classNames(
                          'font-jost select-none text-xs sm:text-xl -rotate-45 absolute right-[30%] bottom-[35%]',
                          option.isParticle ? 'text-[#FFF9F5]' : 'text-black',
                        )}
                      >
                        {option.subTitle}
                      </span>
                    </div>
                  );
                })}
          </motion.div>
          <img
            src={play_Roulette_Btn_spin_beige}
            className="absolute w-1/5 z-10 select-none"
          />
          <div className="z-10 font-jost text-white wheel-of-fortune-text-shadow font-bold select-none text-lg sm:text-2xl">
            Spin
          </div>
          {wheelOfFortuneProps.options.length === 0 && (
            <>
              <div className="absolute z-10 font-sigmarOne text-white wheel-of-fortune-text-shadow select-none text-2xl sm:text-4xl top-1/4">
                No options
              </div>
              <div className="absolute z-10 font-sigmarOne text-white wheel-of-fortune-text-shadow select-none text-2xl sm:text-4xl bottom-1/4">
                No options
              </div>
            </>
          )}
        </div>
      );
  }
};

export default WheelOfFortuneFactory;
