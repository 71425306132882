import {useRef, useState, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import WheelOfFortuneEventHandler, {
  WheelOfFortuneIntegrationRef,
} from '../../../components/scriptIntegrations/wheelOfFortuneEventHandler/WheelOfFortuneEventHandler';
import WheelOfFortuneAnimations from '../../../components/wheelOfFortune/animations/WheelOfFortuneAnimations';
import {
  WheelOfFortuneSectionsTypes,
  WheelOfFortuneColorTypes,
} from './WheelOfFortuneFactory';
import {wheelOfFortuneTestData} from './WheelOfFortuneTestData';

export interface WheelOfFortuneOptionsPrizeData {
  img: string;
  title: string;
  description: string;
}

interface WheelOfFortuneOptionsData {
  img: string;
  title: string;
  subTitle?: string;
  color: string;
  dropChance: string;
  id: string;
  prize: WheelOfFortuneOptionsPrizeData;
  isParticle: boolean;
}

export interface WheelOfFortuneData {
  durationInSeconds: number;
  options: WheelOfFortuneOptionsData[];
  isSlowSpeedReduction?: boolean;
}

const WheelOfFortuneWrapper: React.FC = ({}) => {
  const {type, sections, id} = useParams<{
    type: WheelOfFortuneColorTypes;
    sections: WheelOfFortuneSectionsTypes;
    id: string;
  }>();

  // const [localData, setLocalData] = useState<WheelOfFortuneData>({
  //   durationInSeconds: 5,
  //   options: [],
  // });
  const [localData, setLocalData] = useState<WheelOfFortuneData>(
    wheelOfFortuneTestData,
  );
  const [isStartSpin, setIsStartSpin] = useState(false);

  const wheelOfFortuneIntegrationRef =
    useRef<WheelOfFortuneIntegrationRef>(null);

  const startSpin = useCallback(() => {
    setIsStartSpin(true);
  }, []);

  const stopSpin = useCallback(() => {
    setIsStartSpin(false);
  }, []);

  return (
    <>
      <WheelOfFortuneEventHandler
        componentId={id || 'wheelOfFortune'}
        ref={wheelOfFortuneIntegrationRef}
        setParams={(wheelOfFortuneData) => {
          setLocalData(wheelOfFortuneData);
        }}
        setStartSpin={() => {
          startSpin();
        }}
      />

      <WheelOfFortuneAnimations
        wheelOfFortuneData={localData}
        optionWinnedWithId={(idd) =>
          wheelOfFortuneIntegrationRef.current?.optionWinnedWithId(idd)
        }
        isStartSpin={isStartSpin}
        startSpin={startSpin}
        stopSpin={stopSpin}
        type={type || 'blue'}
        sections={sections || 'adaptive'}
      />
    </>
  );
};

export default WheelOfFortuneWrapper;
